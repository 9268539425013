import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

import { Breakpoints } from '../../styles/breakpoints'
import { Colors } from '../../styles/colors'
import { fontFamilies } from './fontFamilies'
import { fontWeight } from './fontWeight'

const DisableDecoration = css`
  text-decoration: none;
`

const CommonBody = styled.span`
  ${({ hideDecoration }) => hideDecoration && DisableDecoration};
  color: ${Colors['text-grey']};
`

const bodyStyles = {
  xLarge: styled(CommonBody)`
    font-family: ${props => fontFamilies[props.font]};
    font-size: 24px;
    font-weight: ${fontWeight.Normal};
    letter-spacing: 0;
    line-height: ${({ condensed }) => (condensed ? 28 : 34)}px;
    text-transform: initial;
    ${Breakpoints.medium} {
      font-size: 20px;
      line-height: ${({ condensed }) => (condensed ? 23 : 29)}px;
    }
  `,
  large: styled(CommonBody)`
    font-family: ${props => fontFamilies[props.font]};
    font-size: 18px;
    font-weight: ${fontWeight.Normal};
    letter-spacing: 0.25px;
    line-height: ${({ condensed }) => (condensed ? 23 : 28)}px;
    text-transform: initial;
  `,
  medium: styled(CommonBody)`
    font-family: ${props => fontFamilies[props.font]};
    font-size: 16px;
    font-weight: ${fontWeight.Normal};
    letter-spacing: 0.25px;
    line-height: ${({ condensed }) => (condensed ? 19 : 24)}px;
    text-transform: initial;
  `,
  small: styled(CommonBody)`
    font-family: ${props => fontFamilies[props.font]};
    font-size: 14px;
    font-weight: ${fontWeight.Normal};
    letter-spacing: 0.25px;
    line-height: ${({ condensed }) => (condensed ? 17 : 22)}px;
    text-transform: initial;
  `,
  xSmall: styled(CommonBody)`
    font-family: ${props => fontFamilies[props.font]};
    font-size: 12px;
    font-weight: ${fontWeight.Normal};
    letter-spacing: 0.16px;
    line-height: ${({ condensed }) => (condensed ? 15 : 18)}px;
    text-transform: initial;
  `,
  mini: styled(CommonBody)`
    font-family: ${props => fontFamilies[props.font]};
    font-size: 10px;
    font-weight: ${fontWeight.Normal};
    letter-spacing: 0.14px;
    line-height: 12px;
  `,
}

export const Text = ({ children, variant, className, font, ...rest }) => {
  const Text = bodyStyles[variant]
  const useFont = font ?? 'Arbutus'
  return (
    <Text font={useFont} {...rest} className={`${className ? className : ''}`}>
      {children}
    </Text>
  )
}

Text.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['xSmall', 'small', 'medium', 'large', 'xLarge']),
  className: PropTypes.string,
  font: PropTypes.oneOf(['Roboto', 'Arbutus']),
}
