import { styled } from '@material-ui/core'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { Text } from '../../atoms/Typography/Text'
import { Colors } from '../../styles/colors'

const StyledHamburgerMenu = styled('div')({
  // display this menu only on tablet/mobile devices
  '@media (min-width: 960px)': {
    display: 'none',
  },

  display: 'flex',
  flexDirection: 'row',
})

const Wrapper = styled('div')({
  margin: 'auto 0',
  position: 'relative',
  width: '30px',
  height: '50px',
  zIndex: 1403,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-end',
})

const MenuToggler = styled('input')({
  opacity: 0,
  position: 'absolute',
  left: '15px',
  width: '50px',
  height: '50px',
  cursor: 'pointer',
  zIndex: 1405,
})

const Hamburger = styled('div')({
  position: 'absolute',
  zIndex: 1403,
  width: '18px',
  height: '18px',
  padding: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const HamburgerInnards = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 'none',
  width: '100%',
  height: '2px',
  background: Colors.secondary,
  transition: 'all 0.3s ease',
  zIndex: 1403,
  borderRadius: '1px',
  transform: props => (props.styled.toggled ? 'rotate(45deg)' : 'none'),

  '&:after, &:before': {
    borderRadius: '1px',
    content: '""',
    position: 'absolute',
    zIndex: 1403,
    width: '100%',
    height: '2px',
    background: 'inherit',
    transition: 'all 0.3s ease',
  },

  '&:before': {
    top: props => (props.styled.toggled ? '0' : '-6px'),
  },

  '&:after': {
    bottom: props => (props.styled.toggled ? '0' : '-6px'),
    transform: props => (props.styled.toggled ? 'rotate(-90deg)' : 'none'),
  },
})

const MenuElements = styled('ul')({
  overflowY: 'auto',
  position: 'fixed',
  top: 80,
  left: 0,
  bottom: 0,
  width: '100vw',
  margin: '0',
  backgroundColor: Colors.background,
  borderTop: '1px solid #ebecf2',
  zIndex: 1402,
  transition: 'visibility 0.3s ease, opacity 0.3s ease',
  visibility: props => (props.styled.toggled ? 'visible' : 'hidden'),
  opacity: props => (props.styled.toggled ? '1' : '0'),

  listStyleType: 'none',

  display: 'grid',
  gridTemplateRows: 'repeat(5, 80px)',
})

const Element = styled('li')({
  position: 'relative',
  margin: 'auto 0',
  textAlign: 'center',
  fontSize: 'larger',
})

const Anchor = styled(Link)({
  display: 'inline-block',
  textDecoration: 'none',
  '&:hover': {
    transition: 'all 0.4s ease',
    backgroundColor: `${Colors.grey}30`,
    boxShadow: '0 5px 10px 0 rgba(41,41,43,.07)',
    fontWeight: 'bold',
  },
  width: '100%',
  height: '100%',
  textAlign: 'center',
  padding: '15px',
})

const AnchorMenu = styled('a')({
  textDecoration: 'none',
  alignSelf: 'center',
  cursor: 'pointer',
})

const GreenText = styled(Text)({
  '&&': {
    color: Colors.secondary,
    fontWeight: 'bold',
  },
})

export const HamburgerMenu = ({ menuConfig }) => {
  const [toggled, setToggled] = useState(false)

  const menuElements = menuConfig.map(({ name, url, ...rest }) => (
    <Element key={name}>
      <Anchor to={url} {...rest}>
        <GreenText font='Roboto' variant='large'>{name}</GreenText>
      </Anchor>
    </Element>
  ))

  return (
    <StyledHamburgerMenu>
      <AnchorMenu onClick={() => setToggled(!toggled)}>
        <GreenText font='Roboto' variant='medium'>Menu</GreenText>
      </AnchorMenu>
      <Wrapper>
        <MenuToggler type='checkbox' onChange={() => setToggled(!toggled)} />
        <Hamburger>
          <HamburgerInnards styled={{ toggled }} />
        </Hamburger>
      </Wrapper>

      <MenuElements styled={{ toggled }}>{menuElements}</MenuElements>
    </StyledHamburgerMenu>
  )
}

HamburgerMenu.propTypes = {
  menuConfig: PropTypes.array,
}
