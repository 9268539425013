import '../locales/i18n'
import './layout.css'
import './../../smoothscroll.min'

import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { Footer } from '../molecules/Footer/Footer'
import { CookieConsent } from '../organisms/CookieConsent/CookieConsent'
import Navbar from '../organisms/Navbar/Navbar'
import { Breakpoints } from '../styles/breakpoints'
import { Colors } from '../styles/colors'

const MainWrapper = styled.div`
  background-color: ${Colors.background};
  height: 100%;
  padding: 0;
  margin-top: 177px;
  width: 100%;

  ${Breakpoints.medium} {
    margin-top: 80px;
  }
`

const Main = ({ children }) => {
  const [showConsent, setShowConsent] = React.useState(true)

  React.useEffect(() => {
    if (window.localStorage.getItem('hasViewedCookieConsent') === 'true') {
      setShowConsent(false)
    }
  })

  return (
    <>
      <Navbar />
      <MainWrapper>
        <main>{children}</main>
      </MainWrapper>
      <Footer />
      {showConsent && (
        <CookieConsent
          onAccept={() => {
            setShowConsent(false)
            window.localStorage.setItem('hasViewedCookieConsent', 'true')
          }}
        />
      )}
    </>
  )
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Main
