import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { Colors } from '../../styles/colors'

const ButtonBase = styled.button`
  background-color: ${Colors.background};
  border: 2px solid ${Colors.primary};
  border-radius: 100px;
  color: ${Colors.primary};
  cursor: pointer;
  margin: 0;
  padding: 12px 14px;
  position: relative;
  transition: all 0.5s;
  width: 100%;

  & > span {
    color: ${Colors.primary};
    transition: all 0.5s;
    padding: 0 25px;
  }

  &:hover,
  &:focus,
  &:active {
    border-color: ${Colors.secondary};
    & > span {
      color: ${Colors.secondary};
    }
  }
  
  // FIXME: disabled state
  &:disabled {
    background-color: ${Colors.grey};
    cursor: default;
  }
`
export const Button = ({ children, ...props }) => (
  <ButtonBase {...props}>{children}</ButtonBase>
)

Button.propTypes = {
  children: PropTypes.node,
}
