export const Colors = {
  primary: '#2bc1b9',
  secondary: '#1b9b8e',
  font: '#808080',
  fontDarker: '#505050',
  lighterGrey: '#F7F5F5',
  lightGrey: '#e5e5e5',
  grey: '#cccccc',
  background: '#ffffff',
  black: '#000000',
  'text-grey': '#666666',
  white: '#ffffff',

  pastel: {
    violet: '#aa7df8',
    'blue-light2': '#f1f6fb',
    'blue-light': '#e6f5f8',
    blue: '#56bbd0',
    'blue-bright': '#0555ff',
    'blue-button': '#003bff',
    'dark-blue': '#455880',
    'darker-blue': '#1E256C',
    'light-pink': '#fefcfb',
    pink: '#fdaabb',
    yellow: '#fdbc4d',
    'yellow-light': '#fff9ea',
    green: '#4cd173',
    'violet-light': '#f2f5fb',
  },

  cards: {
    blue: '#d2edf3',
    pink: '#faf0eb',
    yellow: '#fff9ea',
  },
}
