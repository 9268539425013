import { styled } from '@material-ui/core'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'

import { fontFamilies } from '../../atoms/Typography/fontFamilies'
import { fontWeight } from '../../atoms/Typography/fontWeight'
import { Breakpoints } from '../../styles/breakpoints'
import { Colors } from '../../styles/colors'

// eslint-disable-next-line no-unused-vars
const LogoWrapper = styled(({ zIndex, ...rest }) => <div {...rest} />)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  zIndex: props => props.zIndex ?? 1404,
})

const HomepageAnchor = styled(Link)({
  width: '63px',
  marginRight: '10px',

  [Breakpoints.large]: {
    width: '53px',
  },

  [Breakpoints.small]: {
    width: '36px',
  },
})

const ImageWrapper = styled(Img)({
  width: '63px',

  [Breakpoints.large]: {
    width: '53px',
  },

  [Breakpoints.small]: {
    width: '36px',
  },
})

const Name = styled(Link)({
  color: Colors['text-grey'],
  fontWeight: fontWeight.Normal,
  fontSize: '24px',
  fontFamily: fontFamilies.Arbutus,
  textDecoration: 'none',
  letterSpacing: '0.13px',
  lineHeight: '34px',
  [Breakpoints.large]: {
    fontSize: '20px',
    letterSpacing: '0.1px',
    lineHeight: '28px',
  },
  [Breakpoints.small]: {
    // text variant small
    fontSize: '16px',
    letterSpacing: '0.25px',
    lineHeight: '17px',
  },
})

const LogoWithName = ({ zIndex }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "sygnet.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <LogoWrapper zIndex={zIndex}>
      <HomepageAnchor to='/'>
        <ImageWrapper fluid={data.image.childImageSharp.fluid} />
      </HomepageAnchor>
      <Name to='/'>Poradnia Rehabilitacyjna Azory</Name>
    </LogoWrapper>
  )
}

LogoWithName.propTypes = {
  zIndex: PropTypes.number,
}

export default LogoWithName
