import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { Text } from '../../atoms/Typography/Text'
import { Button } from '../../molecules/Button/Button'
import { Breakpoints } from '../../styles/breakpoints'
import { Colors } from '../../styles/colors'
import { Bold } from '../../styles/common'

const FloatingBar = styled.div`
  position: fixed;
  z-index: 1403;
  bottom: 0;
  height: 45px;
  background-color: ${Colors.background};
  width: 100%;
  border-top: 2px solid #ebecf2;
  transition: all 0.5 ease;

  display: grid;
  grid-template-columns: 1fr 150px;
  
  ${Breakpoints.small} {
    height: 65px;
    grid-template-columns: 1fr 130px;
  }
`

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SmallButton = styled(Button)`
  padding: 0;
  width: auto;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  margin-left: 8px;
  
  ${Breakpoints.small} {
    margin-left: 4px;
  }
`

const VariableText = styled(Text)`
  ${Breakpoints.small} {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.16px;
    line-height: 18px;
  }
`

const GreenText = styled(VariableText)`
  && {
    color: ${Colors.secondary};
    font-weight: bold;
  }
`

const TextWrapper = styled.div`
  margin-left: 8px;
  line-height: 10px;
`

// eslint-disable-next-line react/prop-types
export const CookieConsent = ({ onAccept }) => {
  return (
    <FloatingBar>
      <FlexWrapper>
        <TextWrapper>
          <VariableText variant='small'>Korzystając z tej witryny wyrażasz zgodę na wykorzystanie plików cookies.</VariableText>
          <StyledLink to='/politykaCookies'>
            <GreenText variant='small'>
              <em>Polityka cookies</em>
            </GreenText>
          </StyledLink>
        </TextWrapper>
      </FlexWrapper>
      <FlexWrapper>
        <SmallButton onClick={() => onAccept()}>
          <Text variant='small'>
            <Bold>Rozumiem</Bold>
          </Text>
        </SmallButton>
      </FlexWrapper>
    </FloatingBar>
  )
}
