import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { Breakpoints } from '../../styles/breakpoints'
import { Colors } from '../../styles/colors'
import { fontFamilies } from './fontFamilies'
import { fontWeight } from './fontWeight'

const CommonHeading = styled.h1`
  font-family: ${props => fontFamilies[props.font]};
  font-weight: ${fontWeight.Bold};
  color: ${Colors.secondary};
`

const headingStyles = {
  heading1: styled(CommonHeading)`
    font-size: 64px;
    letter-spacing: 0.27px;
    line-height: 64px;
    text-transform: initial;

    ${Breakpoints.xLarge} {
      font-size: 70px;
      letter-spacing: 0.3px;
      line-height: 70px;
    }
    ${Breakpoints.medium} {
      font-size: 46px;
      letter-spacing: 0.2px;
      line-height: 46px;
    }
  `,
  heading2: styled(CommonHeading)`
    font-size: 40px;
    letter-spacing: 0.17px;
    line-height: 46px;
    text-transform: initial;
    ${Breakpoints.large} {
      font-size: 30px;
      letter-spacing: 0.15px;
      line-height: 34px;
    }
  `,
  heading3: styled(CommonHeading)`
    font-size: 30px;
    letter-spacing: 0.13px;
    line-height: 34px;
    text-transform: initial;
    ${Breakpoints.large} {
      font-size: 24px;
      letter-spacing: 0.1px;
      line-height: 28px;
    }
  `,
}

const useHeadingType = variant => {
  switch (variant) {
  case 'heading2':
    return 'h2'
  case 'heading3':
    return 'h3'
  case 'heading1':
  default:
    return 'h1'
  }
}

export const Header = ({ children, variant, className, font, ...rest }) => {
  const Heading = headingStyles[variant]
  const headingType = useHeadingType(variant)
  const useFont = font ?? 'Roboto'

  return (
    <Heading
      as={headingType}
      font={useFont}
      {...rest}
      className={`${className ? className : ''}`}
    >
      {children}
    </Heading>
  )
}

Header.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['heading1', 'heading2', 'heading3']),
  className: PropTypes.string,
  font: PropTypes.oneOf(['Roboto', 'Arbutus']),
}
