export const fontWeight = {
  Thin: 100,
  ['Extra Light']: 200,
  Light: 300,
  Book: 350,
  Normal: 400,
  Medium: 500,
  ['Semi Bold']: 600,
  Bold: 700,
  ['Extra Bold']: 800,
  Black: 900,
}
