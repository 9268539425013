import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { Text } from '../../atoms/Typography/Text'
import LogoWithName from '../../components/Logo/LogoWithName'
import { menuConfig } from '../../config'
import { Breakpoints } from '../../styles/breakpoints'
import { Colors } from '../../styles/colors'
import { Bold, NonStyledAnchor } from '../../styles/common'
import { Spacings } from '../../styles/spacings'
import { SectionWrapper } from '../SectionWrapper/SectionWrapper'

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${Breakpoints.medium} {
    grid-template-columns: 0.55fr 1fr;
  }

  ${Breakpoints.small} {
    grid-template-columns: 1fr;
  }
`

const SubSection = styled.div`
  display: flex;
  flex-direction: column;
`

const Element = styled.li`
  margin: auto 0;
  list-style-type: none;
  margin-bottom: ${Spacings['32']};
`

const Anchor = styled(Link)`
  display: inline-block;
  text-decoration: none;
  width: 100%;
  height: 100%;
`

const GreenText = styled(Text)`
  && {
    color: ${Colors.secondary};
    font-weight: bold;
  }
`

const SpacedLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${Breakpoints.small} {
    grid-template-columns: 1fr;
  }
  margin-bottom: ${Spacings['52']};
  margin-top: ${Spacings['52']};
  max-width: 550px;

  ${Breakpoints.small} {
    margin-top: ${Spacings['32']};
    min-width: 0;
  }
`

const OpeningHoursText = styled(Text)`
  font-style: normal;
  font-weight: 300;
  line-height: 23px;
`

const AlignRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 222px;

  ${Breakpoints.small} {
    align-items: unset;
    margin-top: ${Spacings['22']};
  }
`

export const Footer = () => {
  const menuElements = menuConfig.map(({ name, url, ...rest }) => (
    <Element key={name}>
      <Anchor to={url} {...rest}>
        <GreenText font='Roboto' variant='medium'>
          {name}
        </GreenText>
      </Anchor>
    </Element>
  ))

  return (
    <SectionWrapper backgroundColor={Colors.lighterGrey} paddingTop paddingBottom>
      <Layout>
        <SubSection>{menuElements}</SubSection>
        <SubSection>
          <LogoWithName zIndex={1} />
          <SpacedLayout>
            <div>
              <Text font='Roboto' variant='medium'>
                <Bold>Adres</Bold>
              </Text>
              <br />
              <br />
              <Text font='Roboto' variant='large'>
                <Bold>ul. Nałkowskiego 1</Bold>
              </Text>
              <br />
              <Text font='Roboto' variant='large'>
                31-308 Kraków
              </Text>
              <br />
              <br />
              <Text font='Roboto' variant='xLarge'>
                <Bold>
                  <NonStyledAnchor href='tel:12.637.21.16'>12 637 21 16</NonStyledAnchor>
                </Bold>
              </Text>
            </div>
            <AlignRight>
              <Text font='Roboto' variant='medium'>
                <Bold>Godziny otwarcia</Bold>
              </Text>
              <br />
              <OpeningHoursText font='Roboto' condensed variant='medium'>
                poniedziałek: 8:00 - 19:00
              </OpeningHoursText>
              <OpeningHoursText font='Roboto' condensed variant='medium'>
                wtorek: 8:00 - 21:00
              </OpeningHoursText>
              <OpeningHoursText font='Roboto' condensed variant='medium'>
                środa: 8:00 - 19:00
              </OpeningHoursText>
              <OpeningHoursText font='Roboto' condensed variant='medium'>
                czwartek: 8:00 - 21:00
              </OpeningHoursText>
              <OpeningHoursText font='Roboto' condensed variant='medium'>
                piątek: 8:00 - 19:00
              </OpeningHoursText>
            </AlignRight>
          </SpacedLayout>
        </SubSection>
      </Layout>
    </SectionWrapper>
  )
}
