export const menuConfig = [
  {
    name: 'Zabiegi refundowane',
    url: '/zabiegiRefundowane',
  },
  {
    name: 'Wizyty prywatne',
    url: '/wizytyPrywatne',
  },
  {
    name: 'O nas',
    url: '/oNas',
  },
  {
    name: 'Cennik',
    url: '/wizytyPrywatne',
  },
  {
    name: 'Umów wizytę',
    url: '/kontakt',
  },
]
