export const Spacings = {
  4: '4px',
  8: '8px',
  12: '12px',
  18: '18px',
  22: '22px',
  32: '32px',
  42: '42px',
  52: '52px',
}
