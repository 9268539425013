import { styled } from '@material-ui/core/styles'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'

import { Text } from '../../atoms/Typography/Text'
import LogoWithName from '../../components/Logo/LogoWithName'
import { menuConfig } from '../../config'
import { Breakpoints } from '../../styles/breakpoints'
import { Colors } from '../../styles/colors'
import { NonStyledAnchor } from '../../styles/common'
import { HamburgerMenu } from './HamburgerMenu'

const MenuElements = styled('ul')({
  // display this menu only on desktop size
  '@media (max-width: 959px)': {
    display: 'none',
  },

  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  listStyleType: 'none',
  margin: '0px',
  width: '100%',
})

const Element = styled('li')({
  margin: 'auto 0',
  textAlign: 'center',
})

const Anchor = styled(Link)({
  display: 'inline-block',
  textDecoration: 'none',
  // color: Colors.fontDarker,
  // '&:hover': {
  //   color: Colors.secondary,
  // },
  width: '100%',
  height: '100%',
  textAlign: 'center',
  // padding: '15px',
  // fontWeight: 'bold',
})

const NavbarWrapper = styled('div')({
  position: 'fixed',
  ['-webkit-backface-visibility']: 'hidden',
  zIndex: 1403,
  top: 0,
  height: props => (!props.styled.scroll ? '178px' : '177px'),
  width: '100%',
  backgroundColor: Colors.background,
  borderBottom: props => (!props.styled.scroll ? '1px solid #ebecf2' : ''),
  padding: '0',

  [Breakpoints.medium]: {
    height: props => (!props.styled.scroll ? '81px' : '80px'),
  },
})

const BorderWrapper = styled('div')({
  height: '110px',
  borderBottom: '1px solid #ebecf2',

  [Breakpoints.medium]: {
    height: '80px',
  },
})

const PaddedInsides = styled('div')({
  height: '109px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '1180px',

  [Breakpoints.large]: {
    padding: '0 30px 0 30px',
    maxWidth: 'inherit',
  },

  [Breakpoints.medium]: {
    height: '80px',
  },
})

const SubMenu = styled('div')({
  height: '67px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '1180px',
  // left: '50%',
  // transform: 'translateX(-50%)',

  [Breakpoints.large]: {
    padding: '0 30px 0 30px',
    maxWidth: 'inherit',
  },

  '@media (max-width: 959px)': {
    display: 'none',
  },
})

const OpeningHours = styled('div')({
  display: 'flex',
  flexDirection: 'column',

  [Breakpoints.medium]: {
    display: 'none',
  },
})

const TelephoneNumber = styled(Text)({
  paddingLeft: '150px',

  [Breakpoints.medium]: {
    display: 'none',
  },
})

const Navbar = () => {
  const GreenText = styled(Text)({
    '&&': {
      color: Colors.secondary,
      fontWeight: 'bold',
    },
  })

  const menuElements = menuConfig.map(({ name, url, ...rest }) => (
    <Element key={name}>
      <Anchor to={url} {...rest}>
        <GreenText font='Roboto' variant='medium'>
          {name}
        </GreenText>
      </Anchor>
    </Element>
  ))

  const [scroll, setScroll] = useState(true)
  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY < 1
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck)
      }
    })
  })

  return (
    <NavbarWrapper styled={{ scroll }}>
      <BorderWrapper>
        <PaddedInsides>
          <LogoWithName />
          <TelephoneNumber variant='xLarge'>
            <NonStyledAnchor href='tel:12.637.21.16'>tel. 12 637 21 16</NonStyledAnchor>
          </TelephoneNumber>
          <OpeningHours>
            <Text variant='medium'>Godziny otwarcia</Text>
            <Text variant='medium'>pn/śr/pt: 8:00 - 19:00</Text>
            <Text variant='medium'>wt/czw: 8:00 - 21:00</Text>
          </OpeningHours>
          <HamburgerMenu menuConfig={menuConfig} />
        </PaddedInsides>
      </BorderWrapper>
      <SubMenu>
        <MenuElements>{menuElements}</MenuElements>
      </SubMenu>
    </NavbarWrapper>
  )
}

Navbar.propTypes = {}

export default Navbar
