import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { Breakpoints } from '../../styles/breakpoints'
import { Colors } from '../../styles/colors'
import { Spacings } from '../../styles/spacings'

const BackgroundWrapper = styled.div`
  background-color: ${props => props.backgroundColor ?? Colors.background};
  ${props => props.background && `background: ${props.background};`};
`

const PaddedInsides = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1180px;
  padding-top: ${props => props.paddingTop && Spacings[52]};
  padding-bottom: ${props => props.paddingBottom && Spacings[52]};

  ${Breakpoints.large} {
    max-width: inherit;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: ${props => props.paddingTop && Spacings[52]};
    padding-bottom: ${props => props.paddingBottom && Spacings[52]};
  }

  ${Breakpoints.small} {
    padding-top: ${props => props.paddingTop && Spacings[32]};
    padding-bottom: ${props => props.paddingBottom && Spacings[32]};
  }
`

export const SectionWrapper = ({
  children,
  backgroundColor,
  paddingTop,
  paddingBottom,
  background,
}) => (
  <BackgroundWrapper backgroundColor={backgroundColor} background={background}>
    <PaddedInsides paddingTop={paddingTop} paddingBottom={paddingBottom}>
      {children}
    </PaddedInsides>
  </BackgroundWrapper>
)

SectionWrapper.propTypes = {
  children: PropTypes.node,
  background: PropTypes.string,
  backgroundColor: PropTypes.string,
  paddingTop: PropTypes.bool,
  paddingBottom: PropTypes.bool,
}
