import styled from 'styled-components'

import { fontWeight } from '../atoms/Typography/fontWeight'

export const Bold = styled.span`
  font-weight: ${fontWeight.Bold};
`

export const NonStyledAnchor = styled.a`
  text-decoration: none;
  color: inherit;
`
